import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet és al principi campanulat i després més obert, d’1,5 a 4 cm de diàmetre i estriat per transparència, de color rosat a marró rosat. Les làmines estan espaiades i de color rosadet amb lamel·les ventrudes. El peu de dret a corbat, de color groguenc a groc daurat, fistulós i amb rizoides blancs. Les espores són blanques en massa, el·lipsoides, de 7,5-10 x 4,5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      